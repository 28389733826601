.topic-search {
  position: relative;
  background-Color: #FFF;

  .topic-search:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  &.is-open {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .Select-control {
    border: none;
    background: transparent;
    width: 100%;
    padding: 5px;
    height: 46px;

    input {
      padding: 6px 0px;
      line-height: 22px;
      font-size: 15px;
    }

    .Select-placeholder + .Select-input {
      padding-left: 15px;
    }

    .Select-placeholder {
      line-height: 42px;
      font-size: 15px;
    }

    .Select-value {
      line-height: 22px;
    }
  }

  .Select-control:hover {
    box-shadow: none;
  }
  .Select.is-open > .Select-control {
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background: transparent;
    border-color: #b3b3b3 #ccc #d9d9d9;
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #007eff;
    box-shadow: none;;
    background: transparent;
  }

  .Select--multi {
    vertical-align: middle;
  }
  .Select--multi .Select-value {
    border-radius: 24px;
    margin: 3px;
  }

  .Select-arrow-zone {
    padding-right: 0px;
  }

  .Select-menu-outer {
    border: 1px solid rgba(0, 0, 0, 0.54);
    left: 25px;
    right: 25px;
    width: inherit;
    text-align: left;
  }
}