.MP-CandidatesList {
    border-top: 1px solid #CFCFCF;
    position: relative;

    /* Default background gradient 40% gray -> transparent */
    .hs-shortlist {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: inline;
        background:
            /* Shadow covers */
            linear-gradient(#e9e9e9 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), #e9e9e9 70%) 0 100%,

            /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        background:
            /* Shadow covers */
            linear-gradient(#e9e9e9 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), #e9e9e9 70%) 0 100%,

            /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        background-repeat: no-repeat;
        //background-color: white;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    /* Default background gradient 40% gray -> transparent */
    .vs-shortlist {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: block;
        background:
            /* Shadow covers */
            linear-gradient(#e9e9e9 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), #e9e9e9 70%) 0 100%,

            /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        background:
            /* Shadow covers */
            linear-gradient(#e9e9e9 30%, rgba(255,255,255,0)),
            linear-gradient(rgba(255,255,255,0), #e9e9e9 70%) 0 100%,

            /* Shadows */
            radial-gradient(farthest-side at 50% 0, rgba(0,0,0,.2), rgba(0,0,0,0)),
            radial-gradient(farthest-side at 50% 100%, rgba(0,0,0,.2), rgba(0,0,0,0)) 0 100%;
        background-repeat: no-repeat;
        //background-color: white;
        background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
        /* Opera doesn't support this in the shorthand */
        background-attachment: local, local, scroll, scroll;
    }

    .vs-shortlist,
    .hs-shortlist {
        .Candidate {
            padding: 8px 10px 8px 20px;
            border-left: 4px solid transparent;
            line-height: 32px;
            cursor: pointer;
            /* Small screen and smaller */
            @media (min-width: 992px) {
                border-left: 4px solid transparent;
                border-bottom: 1px solid #CFCFCF;
            }
            /* Small screen and smaller */
            @media (max-width: 991px) {
                border-top: 4px solid transparent;
                border-bottom: 1px solid transparent;
                float: left;
            }

            &.active,
            &:hover {
                background: #fff;
                /* Small screen and smaller */
                @media (min-width: 992px) {
                    border-left: 4px solid #646464;
                }
                /* Small screen and smaller */
                @media (max-width: 991px) {
                    border-top: 4px solid #646464;
                    border-bottom: 1px solid #ffffff;
                }

            }

            &.disabled {
                opacity: 0.50;
            }

            .ThumbnailSquare {
                margin-right: 10px;

                .UnreadBadge.badge {
                    top: -0.2em;
                }
            }

            .label {
                margin-top: 10px;
                float: right;
                font-weight: normal;
            }
        }

        li a {
            display: block;
            color: #000;
            text-decoration: none;
        }
    }

    .VerticalScroll {
        min-height: 75px;
        max-height: 260px;
    }

    .HorizontalScroll {
        min-height: 60px;
        max-height: 60px;
        min-width: 100px;
        max-width: 100%;
    }
}
