.shortlist-colleague-rating {
  .rating-box {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #605ca8;
    min-width: 180px;
    min-height: 160px;
    margin: 0 auto;
    cursor: pointer;
    color: white;
    padding: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #555299;
    }

    .rating-number {
      font-size: 96px;
      line-height: 96px;
    }
  }
}