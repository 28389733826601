.MP-Message {

  .panel {
    width: 100%;
    .panel-body {

    }
    .panel-footer {
      font-size: 85%;
      text-align: right;
    }

  }

  .panel.me {
    color: #336699; //rgb(116, 138, 164);
    background-color: rgb(240, 247, 255);
    border-color: rgb(128, 153, 184);
    .panel-footer {
      padding: 5px 15px;
      color: rgba(116, 138, 164, 0.5);
      background-color: rgb(240, 247, 255);
      border-Top: 1px solid rgba(128, 153, 184, 0.2);
    }
  }

  .panel.spkr {
    color: #333333; //rgb(153, 153, 153);
    background-color: rgb(255, 255, 255);
    .panel-footer {
      padding: 5px 15px;
      color: rgba(153, 153, 153, 0.5);
      border-Top: 1px solid rgba(153, 153, 153, 0.2);
      background-color: rgb(255, 255, 255);
    }
  }

  .mp {
    max-width: 75%;
    padding: 10px 0px;
    color: rgb(153, 153, 153);
    .footer {
      text-align: center;
      color: rgb(153, 153, 153);
      font-size: 75%;
    }
  }

  .strike {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    margin: 8px 0px;
  }
  .strike::before,
  .strike::after {
    content: " ";
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.25);
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }
}
