@import "../../variables.scss";

.flash-wrapper {
  //@media (min-width:$screen-xs-min) and (max-width:$screen-md-min) {
  //  bottom: -100%;
  //}
  //@media (min-width:$screen-md-min) {
  //  top: -100%;
  //}
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.flash-message {
  opacity: 0;
  //@media (min-width:$screen-xs-min) and (max-width:$screen-md-min) {
  //  bottom: -100%;
  //}
  //@media (min-width:$screen-md-min) {
  //  top: -100%;
  //}
  left: 0;
  width: 100%;
  position: fixed;

  /* For Safari 3.1 to 6.0 */
  @media (min-width:$screen-xs-min) and (max-width:$screen-md-min) {
    -webkit-transition: bottom 1.0s, opacity 0.5s;
    -moz-transition: bottom 1.0s, opacity 0.5s;
    -o-transition: bottom 1.0s, opacity 0.5s;
    transition: bottom 1.0s, opacity 0.5s;
  }
  @media (min-width:$screen-md-min) {
    -webkit-transition: top 1.0s, opacity 0.5s;
    -moz-transition: top 1.0s, opacity 0.5s;
    -o-transition: top 1.0s, opacity 0.5s;
    transition: top 1.0s, opacity 0.5s;
  }

  .alert {
    margin-bottom: 0px;
    border-radius: 0px;
  }
}

.flash-show > .flash-message {
  opacity: 1;
  //@media (min-width:$screen-xs-min) and (max-width:$screen-md-min) {
  //  bottom: 0px;
  //}
  //@media (min-width:$screen-md-min) {
  //  top: 0px;
  //}
}