/*
 * built by http://fontello.com/
 * built by http://fontello.com/
 * built by http://fontello.com/
 */
@font-face {
  font-family: 'travel-icons';
  src: url('./travel-icons.eot?56702520');
  src: url('./travel-icons.eot?56702520#iefix') format('embedded-opentype'),
       url('./travel-icons.woff2?56702520') format('woff2'),
       url('./travel-icons.woff?56702520') format('woff'),
       url('./travel-icons.ttf?56702520') format('truetype'),
       url('./travel-icons.svg?56702520#travel-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
 
.travel-icon:before {
  font-family: "travel-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.travel-icon-flight:before { content: '\e800'; } /* '' */
.travel-icon-building:before { content: '\f0f7'; } /* '' */
.travel-icon-cab:before { content: '\f1b9'; } /* '' */
