.AlertBadge.badge {
    background-color:#D9534F;
}

.relative > .AlertBadge.badge {
    //If the parent is relative, put the badge in the top left corner
    position:absolute;
    top:-.5em;
    left:-.8em;
    z-index: 1000;
}
