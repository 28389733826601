@import "../../variables.scss";

.StepNumber {
  position: relative;
  margin-bottom: 15px;

  .StepNumber-n {
    float: left;
    background: #78A350;
    color: #fff;

    /* Medium screen and smaller */
    @media (min-width: $screen-md-min) {
      width: 39px;
      height: 39px;
      line-height: 39px;
      border-radius: 40px;
      font-size: 18px;
    }
    /* Small screen and smaller */
    @media (max-width: $screen-sm-max) {
      //left: 50%;
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 25px;
      font-size: 12px;
    }

    text-align: center;
    font-weight: bold;
  }
  hr {
    border-top: none;
    border-bottom: 1px solid #d8d8d8;
    margin: 0;
    /* Medium screen and smaller */
    @media (min-width: $screen-md-min) {
      padding: 19px 0 0 0;
    }
    /* Small screen and smaller */
    @media (max-width: $screen-sm-max) {
      padding: 12px 0 0 0;
    }
  }

  /* h3 is commonly used a child for display a label next to the number */
  h3 {
    display: inline-block;
    margin: 0.25em 0 0 0.5em;
    padding: 0;
    border-bottom: 1px solid #ccc;
    text-align: left;

    /* Small screen and smaller */
    @media (max-width: $screen-sm-max) {
      font-size: 1.5em;
      margin-top: 0;
    }
  }

}

.StepNumber:after {
  clear: both;
  display: table;
  content: " ";
}