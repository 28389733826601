.OnlineBadge.badge {
  background-color: transparent; }

.OnlineBadge.badge-online {
  background-color: #8BBC5D; }

.OnlineBadge.badge-away {
  background-color: #D89932; }

.OnlineBadge.badge-offline {
  background-color: #777; }

.relative > .OnlineBadge.badge {
  font-Weight: normal;
  text-align: center;
  font-Size: xx-small;
  position: absolute;
  bottom: -.5em;
  z-index: 1000; }
