.candidate-list-item {
  padding: 3px;
  display: flex;
  flex-direction: row;

  .not-selected {
    opacity: 0.3;
  }

  .ThumbnailSquare {
    margin-Right: 10px;
  }
}