@import "../../../../variables.scss";

.virtual_badge {
  height: 63px;
  position: absolute;
  bottom: -10px;
  right: 0px;

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    height: 64px;
    position: absolute;
    bottom: -10px;
    right: 0px;
  }
  /* Small screen and smaller */
  @media (max-width: $screen-sm-max) {
    height: 128px;
    position: absolute;
    bottom: -10px;
    right: 0px;
  }

  &.second_badge {
    right: 53px;

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      right: 53px;
    }
    /* Small screen and smaller */
    @media (max-width: $screen-sm-max) {
      right: 109px;
    }
  }
}
