
.speaker-availability {
  span.ThumbnailSquare {
    width: 3em;
    height: 3em;
  }

  &:hover {
    td:last-child button {
      visibility: visible !important;
    }
  }

  .speaker-name {
    font-size:1.5em;
    vertical-align: top;
  }
}

.XXXspeaker-availability {
  padding: 6px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.availability-0, &.availability-undefined {
    .ThumbnailSquare, .availability-body > .speaker-name {
      opacity: 0.75;
    }
  }

  &.availability-1 {
    .ThumbnailSquare, .availability-body > .speaker-name {
      opacity: 1;
    }
  }

  &.availability-2 {
    .ThumbnailSquare, .availability-body > .speaker-name {
      opacity: 1;
    }
  }

  &.availability--1 {
    .ThumbnailSquare, .availability-body > .speaker-name {
      opacity: 0.3;
    }
  }

  .speaker-name {
    a {
      font-size: 8pt;
    }
  }

  .speaker-thumbnail {
    flex: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .ThumbnailSquare > span {
      vertical-align: middle;
      border-radius: 0px;
      transition: opacity 0.25s ease-in-out;
    }
  }

  .availability-body {
    flex: 2 1;
    padding: 6px 12px;

    .speaker-name {
      font-size: 12pt;
    }
  }

  .availability-status {
    flex: 1 1;
    vertical-align: middle;
    width: 100%;
    text-align: right;
  }
}