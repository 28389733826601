@import "../../variables.scss";

$screen-width-hide-n-show-actions: 480px;

.MP-Shortlist {

    margin-Bottom: 40px;

    .row.ShortlistHeader {
        margin-bottom: 3em;
        @media (max-width: $screen-md-min) {
            margin-bottom: 1em;
        }
        h2 {
            @media (max-width: $screen-md-min) {
                font-size: 15px;
            }
        }
    }
    .row.ShortlistMainLayout {//must be on .row
        @media (min-width: $screen-md-min) {
            display: flex;//this makes the columns go full height
        }

        .LeftSideBar {
            @media (min-width: $screen-md-min) {
              padding: 0;
              background: #E9E9E9;
              border-radius: 5px 0 0 5px;
            }
            @media (max-width: $screen-md-min) {
              padding: 0;
              background: #ffffff;
              border-top: 1px #E8E8E8 solid;
              border-right: 1px #E8E8E8 solid;
              border-left: 1px #E8E8E8 solid;
              border-radius: 5px 5px 0 0;
            }

            .btn.Shortlist-msg-all {
                float: right;
                margin-top: 6px;

                display: none;
                @media (max-width: $screen-md-min) {
                    display: inline-block;
                }
            }
            h4 {
                margin: 0;
                padding: 12px 20px;
                @media (max-width: $screen-md-min) {
                    font-size: 13px;
                }
            }
            .MP-CandidatesList {
                .VerticalScroll {
                    @media (max-width: $screen-md-min) {
                        height: 135px;
                    }
                }
            }
            .HideNShow {
                .HideNShow-body {
                    max-height: 500px;
                    overflow: visible;
                }
                .HideNShow-toggle {
                    display: none;
                }
                @media (max-width: $screen-width-hide-n-show-actions) {
                    .HideNShow-body {
                        max-height: 0;
                        overflow: hidden;
                    }
                    &.HideNShow-show .HideNShow-body {
                        max-height: 500px;
                    }
                    .HideNShow-toggle {
                        display: block;
                    }
                }
            }
            .Shortlist-small-screen-right {
                padding: 15px;
                margin: 0 0 0 0;
                h4 {
                    padding: 12px 0;
                    //margin: 0 0 0 0;
                    @media (max-width: $screen-md-min) {
                        margin: 0;
                    }
                }
                @media (max-width: $screen-md-min) {
                    margin: 0;
                    padding-top: 0;
                }
                @media (max-width: $screen-width-hide-n-show-actions) {
                    margin-top: 12px;
                }
            }
            @media (min-width: $screen-width-hide-n-show-actions) and (max-width: $screen-md-min) {
                .Shortlist-small-screen-left {
                    width: 50%;
                    float: left;
                }
                .Shortlist-small-screen-right {
                    width: 50%;
                    float: left;

                    margin: 0;
                    padding-top: 0;
                }
            }
        }

        .CenterArea {
            @media (min-width: $screen-md-min) {
                border-top: 1px #E8E8E8 solid;
                border-bottom: 1px #E8E8E8 solid;
                border-right: 1px #E8E8E8 solid;
                border-radius: 0 5px 5px 0;
            }
            @media (max-width: $screen-md-min) {
                border-top: none;
                border-left: 1px #E8E8E8 solid;
                border-bottom: 1px #E8E8E8 solid;
                border-right: 1px #E8E8E8 solid;
                border-radius: 0 0 5px 5px;
            }
        }
    }
}
