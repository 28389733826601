.InfoBox.alert {
  margin: 0 0 2em 0;
  padding: 10px;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  .headline {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
  }
  button.btn {
    //float: right;
    //margin-left: 1em;
    background: #D89932;
    border: none;
    color: #FFF;
  }

  //span.icon {
  //  float: left;
  //  display: inline-block;
  //  width: 28px;
  //  height: 28px;
  //  margin: 0 20px 0 0;
  //  border-radius: 999px;
  //  background: #FFF;
  //  box-shadow: 0px 2px 4px 0px rgba(217, 155, 56, 0.43);
  //  font-family: Times-BoldItalic;
  //  font-size: 23px;
  //  color: #D89932;
  //  text-align: center;
  //  font-style: italic;
  //}

  @media (min-width: 1px) and (max-width: 767px) {
    display: none;
  }
}
