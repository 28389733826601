.ThumbnailSquare {
  display: inline-block;
  background-repeat: no-repeat;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.59) inset;
  -moz-box-shadow: 0 0 7px rgba(0, 0, 0, 0.59) inset;
  -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.59) inset;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out; }
  .ThumbnailSquare .content-area {
    display: table;
    width: 100%;
    height: 100%; }
    .ThumbnailSquare .content-area > span {
      display: table-cell;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      line-height: 1em; }
  .ThumbnailSquare:hover .tooltip {
    filter: alpha(opacity=90);
    opacity: .9; }
