.IconButton.btn {
    padding: 3px;
    text-align: left;

    .button-text {
        display: inline-block;
        padding: 5px 13px;
    }

    .fa {
        float: left;
        display: inline-block;
        top: 0;

        width: 35px;
        text-align: center;

        height: 30px;
        line-height: 30px;

        font-size: 20px;

        border-right: 1px solid #455B73;
    }
    &.IconButton-icon-only {
        .fa {
            border-right: none;
        }
    }
}
.IconButton.btn-primary {
    .fa {
        color: #455b73;
    }
}
.IconButton.btn-success {
    .fa {
        color: #255625;
    }
}
.IconButton.btn.btn-warning.btn-flat {
    border-color: #f0ad4e;
    color: #f0ad4e;
    background-color: #FFF;
    background-image: none;
    background-position: 0;

    &:hover {
        border-color: #eb9316;
        color: #eb9316;
    }
}
.IconButton.btn.btn-default.btn-flat:hover {
    border-color: #337ab7;
    color: #337ab7;
    background-color: #FFF;
    background-image: none;
    background-position: 0;
}
