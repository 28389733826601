time.icon
{
    font-size: 1em; /* change icon size */
    display: block;
    position: relative;
    width: 7em;
    height: 7em;
    background-color: #fff;
    border-radius: 0.6em;
    box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
    overflow: hidden;
}

time.icon *
{
    display: block;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}

time.icon strong
{
    position: absolute;
    top: 0;
    padding: 0.4em 0;
    color: #fff;
    background-color: #4F7518;
    border-bottom: 1px dashed #7caf1d;
    box-shadow: 0 2px 0 #4F7518;
}

time.icon em
{
    position: absolute;
    bottom: 0.3em;
    color: #4F7518;
}

time.icon span
{
    font-size: 2.8em;
    letter-spacing: -0.05em;
    padding-top: 0.8em;
    color: #2f2f2f;
}