.MP-User {
  .MP-UserList {
    background: #EFEFEF;
    border-top: 1px solid #CFCFCF;
    margin: 0px;
    padding: 0px;

    .UserLink {
      padding: 5px 10px 5px 20px;
      border-left: 4px solid transparent;
      line-height: 32px;
      border-bottom: 1px solid #CFCFCF;
      cursor: pointer;
      list-style: none;

      &.active,
      &:hover {
        background: #fff;
        border-left: 4px solid #646464;
      }

      &.active {
        font-weight: bold;
      }
    }
  }
}
