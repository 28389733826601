.mp-message-preview {
  background-color: #EFEFEF;

  .sender-thumbnail {
    padding: 0px 15px;
  }

  .sender-name {
    font-weight: bold;
  }

  .received-date {
    color: #C7C7C7;
  }

  .message-preview {
    padding: 0px 20px;
  }
}