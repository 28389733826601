.mp-message-send-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  fontSize: 15px;
  padding: 0px;
  height: 30px;
  width: 30px;
  lineHeight: 30px;
  borderRadius: 30px;

  &.lg {
    height: 60px;
    width: 60px;
    lineHeight: 60px;
    borderRadius: 60px;
  }
}