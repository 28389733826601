.shortlist-results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.btn-sharing {
  color: #605ca8;
  border-color: #605ca8;
  background-color: #e2e1fd;
}