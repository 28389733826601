.thumbnail-button {
  position: relative;
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
  zoom: 1.0;
  filter: grayscale(50%);
  transition: all 0.2s ease-in-out;
  margin-bottom: 1em;

  &.empty {
    background-color: '#F8F8F8';
  }

  &.md {
    min-height: 150px;
  }
  &.lg {
    min-height: 250px;
  }

  .background-blur {
    margin: 0px;
    padding: 0px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.25);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;
    min-height: 200px;
    filter: blur(13px);
    left: -10px;
    width: 110%;
  }

  .background-darken {
    margin: 0px;
    padding: 0px;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    overflow: hidden;
    left: -10px;
    width: 110%;
    &.md {
      min-height: 150px;
    }
    &.lg {
      min-height: 250px;
    }
  }

  .background {
    margin: 0px;
    padding: 0px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    overflow: hidden;
    left: 0px;
    width: 100%;
  }

  .title {
    margin: 0px;
    padding: 0px;
    position: absolute;
    color: white;
    line-height: 36px;
    text-align: center;
    width: 100%;
  }

  .background.bottom {
    bottom: 35px;
    background-position: 0px 0px;
    &.md {
      min-height: 115px;
    }
    &.lg {
      min-height: 215px;
    }
  }

  .title.bottom {
    bottom: 0px;
  }

  .background.top {
    top: 35px;
    background-position: 0px -35px;
    &.md {
      min-height: 115px;
    }
    &.lg {
      min-height: 215px;
    }
  }

  .title.top {
    top: 0px;
  }

  .background.middle {
    filter: blur(0px);
    transition: all 0.2s ease-in-out;
    &.md {
      min-height: 150px;
    }
    &.lg {
      min-height: 250px;
    }
  }
  .title.middle {
    top: 40%;
    &.md {
      min-height: 150px;
    }
    &.lg {
      min-height: 250px;
    }
  }

  .background.middle:hover {
    filter: blur(13px);
  }

  &:hover {
    filter: grayscale(0%);
    //transform: scale(1.05);
  }
}