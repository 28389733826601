.embedded_short_list_wrapper {
    z-index: 1;
    /* Medium screen and smaller */
    @media (min-width: 992px) {
        top: 30px;
        right: 30px;
        max-width: 710px;
        min-width: 175px;
        transition: top 0.25s ease-out, right 0.25s ease-out;
    }
    /* Small screen and smaller */
    @media (max-width: 991px) {
        top: 30px;
        left: 0px;
        width: 100%;
        transition: top 0.25s ease-out, left 0.25s ease-out;
    }
}

.affixed.embedded_short_list_wrapper {
    /* Medium screen and smaller */
    @media (min-width: 992px) {
        position: fixed;
        top: 70px;
        right: 30px;
    }
    /* Small screen and smaller */
    @media (max-width: 991px) {
        position: relative;
        width: 100%;
        top: 0px;
    }
}

.MP-EmbeddedShortlist {
    position: relative;
    z-index: 10;
    border: 1px solid #90A5B8;
    padding: 5px 5px 2px 5px;
    box-shadow: 2px 2px 5px #5c5c5c;
    background-color: #B4CDD3;
    transition: height 0.25s ease-out;
    min-height: 75px;

    /* Medium screen and smaller */
    @media (min-width: 992px) {
        //position: fixed;
        float: none;
        top: 0px;
        right: 30px;
    }
    /* Small screen and smaller */
    @media (max-width: 991px) {
        left: 0px;
        top: 0px;
        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.5), 0 0px 20px 0 rgba(0, 0, 0, 0.39);
    }

    .VerticalScroll {
        max-height: 260px;
    }

    .MP-EmbeddedShortlist-top {
        margin: 0px;
        /* Small screen and smaller */
        @media (max-width: 991px) {
            background-color: #748D93;
        }

        .MP-EmbeddedShortlist-item {
            left: 0px;
            transition: width 0.1s ease-out;
            position: relative;
            background-color: #B4CDD3;
            padding: 0px 5px;

            .ThumbnailSquare {
                border-radius: 0 !important;
            }

            .pending_remove {
                @media (max-width: 991px) {
                    width: 65px;
                }
            }
        }
    }

    .MP-EmbeddedShortlist-left {
        float: left;
        margin: 4px 0 4px 4px;
        /* Medium screen and smaller */
        @media (min-width: 992px) {
            minWidth: 60px;
            maxWidth: 400px !important;
        }

        .MP-EmbeddedShortlist-item {
            display: inline-block;
            left: 0px;
            transition: left 0.5s ease-out;
            position: relative;
            background-color: #B4CDD3;

            .MP-EmbeddedShortlist-btn-rm {
                z-index: 1;
                position: absolute;
                top:   -.5em;
                right: -.5em;
                font-Size: .75em;
                display: none;
                color: #DDDDDD;
                /* For Safari 3.1 to 6.0 */
                -webkit-transition: transform 0.15s ease-out;
                /* Standard syntax */
                transition: transform 0.15s ease-out;
            }
            &:hover {
                .MP-EmbeddedShortlist-btn-rm {
                    display: inline-block;
                }
                .MP-EmbeddedShortlist-btn-rm:hover {
                    color: #ffffff;
                    -ms-transform: scale(1.2); /* IE 9 */
                    -webkit-transform: scale(1.2); /* Chrome, Safari, Opera */
                    transform: scale(1.2);
                }
            }

            .ThumbnailSquare {
                border-radius: 0 !important;
            }
        }
        .MP-EmbeddedShortlist-item {
            padding: 0px 5px;
        }
    }

    a.btn {
        animation: wiggle 15s ease;
        animation-iteration-count: infinite;

        @keyframes wiggle {
            94% {
                transform: rotate(0deg);
            }
            95% {
                transform: rotate(4deg);
            }
            96% {
                transform: rotate(-4deg);
            }
            97% {
                transform: rotate(4deg);
            }
            98% {
                transform: rotate(-4deg);
            }
            99% {
                transform: rotate(0deg);
            }
        }
    }
}

.MP-EmbeddedShortlist.pulse {
    animation: pulse 1s ease;
    animation-iteration-count: 1;

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
}

.MP-EmbeddedShortlist.expanded {
    @media (max-width: 991px) {
        max-height: 400px;
    }
}

.MP-EmbeddedShortlist.collapsed {
    @media (max-width: 991px) {
        min-height: 75px;
    }
}
