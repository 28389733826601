.modal-enter {
  opacity: 0.01;
  transition: opacity 0.5s ease-in; }

.modal-enter.modal-enter-active {
  opacity: 1; }

.modal-leave {
  opacity: 1;
  transition: opacity 0.5s ease-in; }

.modal-leave.modal-leave-active {
  opacity: 0.01; }

.modal-appear {
  opacity: 0.01;
  transition: opacity 0.5s ease-in; }

.modal-appear.modal-appear-active {
  opacity: 1; }

.modal-video .modal-header {
  padding: 10px 10px 10px 10px;
  background: #F0F7FF;
  border-bottom: 6px solid #E1EFFF;
  border-radius: 3px 3px 0px 0px;
  color: #7E93AB;
  font-size: 18px;
  text-shadow: 0 1px #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px; }

@media screen and (max-width: 768px) {
  .modal-video .modal-header {
    padding: 5px 5px 5px 5px; }
  .modal-video .modal-header .modal-title {
    font-size: 12px; } }

.modal-header {
  padding: 30px 20px 20px 20px;
  background: #F0F7FF;
  border-bottom: 6px solid #E1EFFF;
  border-radius: 3px 3px 0px 0px;
  color: #7E93AB;
  font-size: 22px;
  text-shadow: 0 1px #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px; }

.modal {
  text-align: center; }

@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%; } }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

.modal-body {
  padding: 2em; }
  .modal-body textarea.form-control {
    resize: vertical; }
