/* Loading Overlay style */
.loader-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: #ffffff;
  opacity: 0.8;
  z-index: 3;
}

.loader-overlay-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #ffffff;
  opacity: 0.8;
  z-index: 3;
}

.loader {
  position: fixed;
  width: 100px;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  will-change: transform;
}

.loader-relative {
  position: relative;
  width: 100px;
  left: 50%;
  top: 50%;
  margin: 50px 0 50px -50px;
  will-change: transform;
}

.loader-relative::before,
.loader::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.circular {
  -webkit-animation: loader-rotate 2s linear 100ms infinite;
  animation: loader-rotate 2s linear 100ms infinite;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: inherit;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loader-dash 1.5s ease-in-out infinite, loader-color 6s ease-in-out infinite;
  animation: loader-dash 1.5s ease-in-out infinite, loader-color 6s ease-in-out infinite;
  stroke-linecap: round;
  will-change: inherit;
}

@-webkit-keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes loader-color {
  0% {
    stroke: #d62d20;
  }
  25% {
    stroke: #0057e7;
  }
  50% {
    stroke: #008744;
  }
  75% {
    stroke: #ffa700;
  }
  100% {
    stroke: #d62d20;
  }
}

@keyframes loader-color {
  0% {
    stroke: #d62d20;
  }
  25% {
    stroke: #0057e7;
  }
  50% {
    stroke: #008744;
  }
  75% {
    stroke: #ffa700;
  }
  100% {
    stroke: #d62d20;
  }
}