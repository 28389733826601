.home-page-search {
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 24px;
  border: 1px solid #ccc;
  position: relative;
  background-Color: #FFF;
  display: table;
  width: 100%;

  .Select-clear {
    display: none;
  }

  .home-page-search:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  &.is-open {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .Select {
    display: table-cell;
    width: 100%;
  }

  .Select-control {
    padding: 5px;
    border: none;
    background: transparent;
    width: 100%;
  }
  .Select-control:hover {
    box-shadow: none;
  }
  .Select.is-open > .Select-control {
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
    background: transparent;
    border-color: #b3b3b3 #ccc #d9d9d9;
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    border-color: #007eff;
    box-shadow: none;;
    background: transparent;
  }

  .Select--multi {
    vertical-align: middle;

    .Select-input {
      margin-left: 2em;
    }

    .Select-value {
      border-radius: 24px;
      line-height: 22px;
      margin: 3px;
    }
  }

  .Select-placeholder {
    line-height: 44px;
  }
  .Select-arrow-zone {
    padding-right: 0px;
  }
  .btn-search .btn {
    margin: 4px;
    display: table-cell;
    border-radius: 24px;
    line-height: 24px;
  }

  .Select-menu-outer {
    border: 1px solid #e6e6e6;
    left: 25px;
    right: 25px;
    width: inherit;
  }
}