.drop-zone {
  border: 1px dashed #C6D0BC;
  padding: 20px 15px 20px 15px;
  background: #F9FFF3;
}

.active-drop-zone {
  border: 1px dashed #C6FFBC;
  padding: 20px 15px 20px 15px;
  background: #F9FFF3;
}