.BMN-EventForm {
  .StepNumber {
    position: relative;
    margin-bottom: 15px;

    .StepNumber-n {
      float: left;
      border: thin #d8d8d8 solid;
      color: #78A350;
      padding-left: 1em;
      padding-right: 1em;
      width: auto;
      height: 39px;
      line-height: 39px;
      border-radius: 40px;
      font-size: 18px;
      text-align: center;
      font-weight: bold;
    }
    hr {
      border-top: none;
      border-bottom: 1px solid #d8d8d8;
      margin: 0;
      padding: 19px 0 0 0;
    }
  }
  .VerticalFields {
    .VerticalField {
      margin-bottom: 40px;
      label {
        text-transform: inherit;
        font-weight: normal;
        letter-spacing: normal;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        input[type=checkbox][name=date_tentative] {
          margin-top: 35px;
        }
      }
    }
  }


  #recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
