.mp-profile-page {
  margin-Bottom: 50px;
  /* social icons below the speaker image */
  /* oneline bio/quote */
  /* Quick stats on the right */
  /* Pricing table */
  /* Featured Video */
  /* Review/Recommendation */
  /* Review/Recommendation */
  /* Profile Tabs */ }
  .mp-profile-page .speaker-headshot {
    padding: 25px 25px 0px 25px;
    position: relative; }
  .mp-profile-page .header {
    margin: 10px 0px;
    color: #77af37;
    text-transform: uppercase;
    padding-bottom: 3px; }
  .mp-profile-page .legend {
    border-bottom: 1px solid #E5E5E5; }
  .mp-profile-page .socialIcons {
    font-size: 27px;
    color: #8E8C8B;
    margin-top: 10px; }
    .mp-profile-page .socialIcons .share-label {
      font-size: 0.5em;
      text-transform: uppercase;
      line-height: 27px;
      position: relative; }
    .mp-profile-page .socialIcons a {
      text-decoration: none;
      text-align: center;
      color: #8E8C8B; }
      .mp-profile-page .socialIcons a:hover {
        color: #637d99; }
  .mp-profile-page .quote {
    position: relative;
    width: 100%;
    margin: 1.5em auto 1.5em auto;
    font-size: 1.4em;
    line-height: 1.4em;
    text-align: left;
    border-left: 5px solid #E8E8E8;
    padding-left: 1em;
    color: #818181; }
    .mp-profile-page .quote i.fa-quote-left {
      position: absolute;
      left: 0px;
      top: 0px;
      opacity: 0.1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)"; }
    .mp-profile-page .quote i.fa-quote-right {
      position: absolute;
      right: 0px;
      top: 0px;
      opacity: 0.1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)"; }
  .mp-profile-page #quickStats .header {
    text-align: center;
    font-weight: 900;
    color: #333; }
  .mp-profile-page #quickStats .associations {
    text-align: center; }
  .mp-profile-page #quickStats .associations img,
  .mp-profile-page #quickStats .awards img {
    max-height: 75px;
    max-width: 75px;
    margin-bottom: 6px;
    opacity: 0.65;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .mp-profile-page #quickStats .thumbnail {
    background: rgba(255, 255, 255, 0.8); }
  .mp-profile-page #quickStats .quickStatsBox {
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px 0;
    display: inline-block;
    padding-bottom: 10px; }
  .mp-profile-page #quickStats .quickStatsBox .progress {
    width: 80%;
    margin: 0 auto;
    height: 5px; }
  .mp-profile-page #quickStats .quickStatsBox .priceRange {
    display: inline-block;
    padding-top: 5px;
    font-size: 23px;
    color: #8E8C8B;
    text-align: center;
    line-height: 1.1em;
    text-transform: lowercase; }
  .mp-profile-page #quickStats .quickStatsBox:last-child {
    border-bottom: none; }
  .mp-profile-page #quickStats .quickStatsBox.ratingSmall a {
    text-decoration: none;
    margin-top: 0;
    display: block; }
  .mp-profile-page #quickStats .quickStatsBox.ratingSmall h4 {
    text-align: center;
    color: #77af37;
    font-size: 27px;
    margin: 10px 0 3px 0;
    padding: 0; }
  .mp-profile-page .speakerPricingTable {
    text-transform: uppercase;
    color: #8E8C8B;
    font-size: 19px; }
    .mp-profile-page .speakerPricingTable h5.header {
      margin: 30px 0px 10px 0px; }
    .mp-profile-page .speakerPricingTable label.header {
      margin: 30px 0px 0px 0px; }
    .mp-profile-page .speakerPricingTable label, .mp-profile-page .speakerPricingTable .labellike {
      font-size: 13px;
      margin: 0;
      cursor: pointer; }
    .mp-profile-page .speakerPricingTable .priceOption {
      text-align: right; }
    .mp-profile-page .speakerPricingTable .feeTop {
      border: none; }
    .mp-profile-page .speakerPricingTable .expander td {
      text-align: right;
      padding: 8px 0 0; }
    .mp-profile-page .speakerPricingTable .expandTableToggle label {
      cursor: pointer; }
    .mp-profile-page .speakerPricingTable .table tbody :first-child td {
      border-top: none !important; }
  .mp-profile-page .profileVideo {
    position: relative; }
    .mp-profile-page .profileVideo img {
      text-align: center; }
    .mp-profile-page .profileVideo i {
      position: absolute;
      display: table;
      opacity: 0.5;
      filter: alpha(opacity=50);
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -35px;
      color: #FFF;
      text-shadow: 0 0 3px black;
      text-decoration: none; }
  .mp-profile-page .reviewProgressBar {
    border-bottom: 1px solid #DDD;
    margin-bottom: 20px; }
    .mp-profile-page .reviewProgressBar .progressBarRating {
      width: 50%; }
    .mp-profile-page .reviewProgressBar label {
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: 1px; }
    .mp-profile-page .reviewProgressBar .review-total {
      text-align: center;
      font-size: 38px; }
    .mp-profile-page .reviewProgressBar .review-recommend-this-speaker {
      font-size: 15px;
      text-align: center;
      margin-top: 11px;
      color: #A8A8A8;
      letter-spacing: 2px; }
    .mp-profile-page .reviewProgressBar .review-post {
      color: #757575;
      font-size: 0.7em;
      text-align: center;
      line-height: 1em; }
  .mp-profile-page .singleReviewBox {
    border-bottom: 1px solid #E7E7E7;
    margin-top: 30px; }
    .mp-profile-page .singleReviewBox .recommendation {
      color: #555; }
    .mp-profile-page .singleReviewBox .marketplaceReview {
      color: #DBA76E;
      text-transform: uppercase;
      text-align: left; }
    .mp-profile-page .singleReviewBox .reviewer {
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      margin-top: 5px;
      color: #757575; }
    .mp-profile-page .singleReviewBox .marketplaceVerified {
      background-color: #ffffea;
      border: 1px solid #ffecb2; }
    .mp-profile-page .singleReviewBox .verified_domain {
      background-color: #fffcab; }
  .mp-profile-page .good {
    color: #77af37; }
  .mp-profile-page #profileTabs .nav-tabs li {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    color: #9b9b9b; }
  .mp-profile-page #profileTabs .tab-content {
    margin-top: 2em; }
  .mp-profile-page .programInfoWrap {
    margin: 30px 0px 60px 0px; }
