@import "../../../../variables.scss";

div.panel-event {
  marginTop: 1.5em;
  marginBottom: 1.5em;
  /* Standard syntax */
  transition: all 0.1s linear;

  .media {
    overflow: visible;
  }

  a {
    text-decoration: none;
  }

  .btn-manage {
    /* Standard syntax */
    transition: visibility 0s, opacity 0.3s linear;
    @media all and (min-width:$screen-md-min) {
      visibility: hidden;
      opacity: 0;
    }
    @media all and (max-width:$screen-sm-max) {
      visibility: hidden;
      opacity: 0;
    }
  }
}

div.panel-event:hover {
  //transform: scale(1.001);
  box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 1);
  //box-shadow: 0px 0px 5px 5px rgba(170,204,255,1);

  .btn-manage {
    @media all and (min-width:$screen-md-min) {
      visibility: visible;
      opacity: 1;
    }
    @media all and (max-width:$screen-sm-max) {
      visibility: hidden;
      opacity: 0;
    }
  }
}