.payment-declined-message {
  top: 160px;
  position: absolute;
}
.payment-declined-frame {
  background: url('PaymentDeclinedSprite.png') no-repeat center top;
  margin: 0 auto;
  width: 225px;
  height: 225px;
}
.payment-declined-frame-0 { background-position: 0 0; }
.payment-declined-frame-1 { background-position: -450px 0; }
.payment-declined-frame-2 { background-position: -675px 0; }
.payment-declined-frame-3 { background-position: -900px 0; }
.payment-declined-frame-4 { background-position: -1125px 0; }
.payment-declined-frame-5 { background-position: -1350px 0; }
.payment-declined-frame-6 { background-position: -1575px 0; }
.payment-declined-frame-7 { background-position: -1800px 0; }
.payment-declined-frame-8 { background-position: -2025px 0; }
.payment-declined-frame-9 { background-position: -2250px 0; }
.payment-declined-frame-10 { background-position: -2475px 0; }
.payment-declined-frame-11 { background-position: -2700px 0; }
.payment-declined-frame-12 { background-position: -2925px 0; }
.payment-declined-frame-13 { background-position: -3150px 0; }
.payment-declined-frame-14 { background-position: -3375px 0; }
.payment-declined-frame-15 { background-position: -3600px 0; }
.payment-declined-frame-16 { background-position: -3825px 0; }
.payment-declined-frame-17 { background-position: -4050px 0; }
.payment-declined-frame-18 { background-position: -4275px 0; }
.payment-declined-frame-19 { background-position: -4500px 0; }
.payment-declined-frame-20 { background-position: -4725px 0; }
.payment-declined-frame-21 { background-position: -4950px 0; }
.payment-declined-frame-22 { background-position: -5175px 0; }
.payment-declined-frame-23 { background-position: -5400px 0; }
.payment-declined-frame-24 { background-position: -5625px 0; }
.payment-declined-frame-25 { background-position: -5850px 0; }
.payment-declined-frame-26 { background-position: -6075px 0; }
.payment-declined-frame-27 { background-position: -6300px 0; }
.payment-declined-frame-28 { background-position: -6525px 0; }
.payment-declined-frame-29 { background-position: -6750px 0; }
.payment-declined-frame-30 { background-position: -6975px 0; }
.payment-declined-frame-31 { background-position: -7200px 0; }
.payment-declined-frame-32 { background-position: -7425px 0; }
.payment-declined-frame-33 { background-position: -7650px 0; }
.payment-declined-frame-34 { background-position: -7875px 0; }
.payment-declined-frame-35 { background-position: -8100px 0; }
.payment-declined-frame-36 { background-position: -8325px 0; }
.payment-declined-frame-37 { background-position: -8550px 0; }
.payment-declined-frame-38 { background-position: -8775px 0; }
.payment-declined-frame-39 { background-position: -9000px 0; }
.payment-declined-frame-40 { background-position: -9225px 0; }
.payment-declined-frame-41 { background-position: -9450px 0; }
.payment-declined-frame-42 { background-position: -9675px 0; }
.payment-declined-frame-43 { background-position: -9900px 0; }
.payment-declined-frame-44 { background-position: -10125px 0; }
.payment-declined-frame-45 { background-position: -10350px 0; }
.payment-declined-frame-46 { background-position: -10575px 0; }
.payment-declined-frame-47 { background-position: -10800px 0; }
.payment-declined-frame-48 { background-position: -11025px 0; }
.payment-declined-frame-49 { background-position: -11250px 0; }
.payment-declined-frame-50 { background-position: -11475px 0; }
.payment-declined-frame-51 { background-position: -11700px 0; }
.payment-declined-frame-52 { background-position: -11925px 0; }
.payment-declined-frame-53 { background-position: -12150px 0; }
.payment-declined-frame-54 { background-position: -12375px 0; }
.payment-declined-frame-55 { background-position: -12600px 0; }
.payment-declined-frame-56 { background-position: -12825px 0; }
.payment-declined-frame-57 { background-position: -13050px 0; }
.payment-declined-frame-58 { background-position: -13275px 0; }
.payment-declined-frame-59 { background-position: -13500px 0; }
.payment-declined-frame-60 { background-position: -13725px 0; }
.payment-declined-frame-61 { background-position: -13950px 0; }
.payment-declined-frame-62 { background-position: -14175px 0; }
.payment-declined-frame-63 { background-position: -14400px 0; }
.payment-declined-frame-64 { background-position: -14625px 0; }
.payment-declined-frame-65 { background-position: -14850px 0; }
.payment-declined-frame-66 { background-position: -15075px 0; }
.payment-declined-frame-67 { background-position: -15300px 0; }
.payment-declined-frame-68 { background-position: -15525px 0; }
.payment-declined-frame-69 { background-position: -15750px 0; }
.payment-declined-frame-70 { background-position: -15975px 0; }
.payment-declined-frame-71 { background-position: -16200px 0; }
.payment-declined-frame-72 { background-position: -16425px 0; }
.payment-declined-frame-73 { background-position: -16650px 0; }
.payment-declined-frame-74 { background-position: -16875px 0; }
.payment-declined-frame-75 { background-position: -17100px 0; }
.payment-declined-frame-76 { background-position: -17325px 0; }
.payment-declined-frame-77 { background-position: -17550px 0; }
.payment-declined-frame-78 { background-position: -17775px 0; }
.payment-declined-frame-79 { background-position: -18000px 0; }
.payment-declined-frame-80 { background-position: -18225px 0; }
.payment-declined-frame-81 { background-position: -18450px 0; }
.payment-declined-frame-82 { background-position: -18675px 0; }
.payment-declined-frame-83 { background-position: -18900px 0; }
.payment-declined-frame-84 { background-position: -19125px 0; }
.payment-declined-frame-85 { background-position: -19350px 0; }
.payment-declined-frame-86 { background-position: -19575px 0; }
.payment-declined-frame-87 { background-position: -19800px 0; }
.payment-declined-frame-88 { background-position: -20025px 0; }