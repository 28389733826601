.speaker-row-wrapper {
  margin: 30px auto;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  & > div[class*='col-'] {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  @media (min-width: 1px) and (max-width: 767px) {
    .speaker-row {
      flex-direction: column;
    }
  }
  @media (min-width: 768px) {
    .speaker-row {
      flex-direction: row;
    }
  }

  .speaker-row {
    border-radius: 0 0 3px 3px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.5);
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    transition: all 0.1s ease-in-out;



    &:hover {
      //transform: scale(1.01);
      box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 1);

      .speaker-details {
        box-shadow: none;
      }

      .speaker-top-actions > .fa {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    .speaker-thumb {
      background-position: top center;
      background-repeat: no-repeat;
      background-color: #C7C7C7;
      background-size: cover;
    }

    @media (min-width: 1px) and (max-width: 767px) {
      .speaker-thumb {
        min-height: 208px;
      }
    }
    @media (min-width: 768px) {
      .speaker-thumb {
        min-width: 208px;
      }
    }

    .speaker-top-actions {
      .fa {
        color: white;
        padding: 5px;
        margin: 5px 5px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        width: 26px;
        height: 26px;
        line-height: 16px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      .shortlisted {
        color: #fff;
        background-color: #fab033;
        border: 1px solid rgba(250, 176, 51, 0.6);
      }
    }

    .speaker-availability {
      margin: 5px 0px 0px 5px;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.9);
      color: #222222;
      font-size: 13px;
      font-weight: 600;
      line-height: 15px;
      text-align: left;
      padding: 4px 8px;
      display: inline-block;

      .fa {
        //color: #80BB3D;
      }
    }

    .speaker-distance {
      margin: 5px 0px 0px 5px;
      border-radius: 2px;
      background-color: rgba(255, 255, 255, 0.9);
      color: #222222;
      font-size: 13px;
      font-weight: 600;
      line-height: 15px;
      padding: 4px 8px;
      display: inline-block;
    }

    .speaker-featured {
      position: absolute;
      left: 40px;
      line-height: 12pt;
      font-Size: 9pt;
      margin: -8pt 0 0;
      color: white;
      background-color: #93C663;
      padding: 3px 10px;
      border-radius: 2px;
      font-weight: bold;
    }

    .speaker-showcased {
      position: absolute;
      left: 40px;
      line-height: 12pt;
      font-Size: 9pt;
      margin: -8pt 0 0;
      color: white;
      background-color: #8BAED3;
      padding: 3px 10px;
      border-radius: 2px;
      font-weight: bold;
    }

    .speaker-details {
      padding: 10px;
      //box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 0.6);
      border-Top: 2px solid #fff;
      height: 225px;
      max-height: 225px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      transition: all 0.2s ease-in-out;
      flex: 2 2 auto;
    }

    .speaker-details.featured {
      border-Top: 2px solid #93C663;
      background: linear-gradient(0deg, rgba(128, 187, 61, 0) 0%, rgba(128, 187, 61, 0.25) 100%) repeat-x;
      background-size: 50px;
    }

    .speaker-details.showcased {
      border-Top: 2px solid #8BAED3;
      background: linear-gradient(0deg, rgba(139, 174, 211, 0) 0%, rgba(139, 174, 211, 0.25) 100%) repeat-x;
      background-size: 50px;
    }

    .speaker-name {
      color: #646464;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      marginBottom: 9px;
      flex: 0 0 auto;
    }

    .speaker-location {
      color: #646464;
      font-size: 14px;
      line-height: 19px;

      .fa {
        color: #8BAED3;
      }
    }

    .speaker-awards {
      color: #646464;
      font-size: 12px;
      line-height: 17px;
      text-align: right;

      .fa {
        color: #8BAED3;
      }
    }

    .speaker-oneline {
      margin: 10px 0px 0px 0px;
      color: #646464;
      font-size: 14px;
      line-height: 23px;
      flex: 2 1 auto;
    }

    .speaker-assets {
      padding: 10px 0px;
      flex: 0 0 auto;

      .col-xs-4, .col-sm-4 {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }

      .speaker-asset {
        margin: 5px 0px;

        .fa {
          color: #8BAED3;
          margin-right: 5px;
        }

        .asset-value {
          padding: 5px 0px;
          color: #646464;
          font-size: 1.2em;
          line-height: 1.0em;
        }

        .asset-name {
          padding: 5px 0px;
          color: #646464;
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          border-top: 1px solid rgba(192, 192, 192, 0.5);
        }
      }
    }

    .speaker-actions {
      margin: 0px 15px;
      flex: 0 0 auto;

      .btn {
        margin: 5px 0px;
        font-size: 0.9em;
        font-weight: 600;
        line-height: 1.2em;
        border-radius: 50px;
        color: white;
        //padding: 6px 20px;
      }

      .btn-green {
        background-color: #80BB3D;
      }

      .btn-green:hover {
        background-color: #93C663;
      }

      .btn-orange {
        background-color: #fab033;
      }

      .btn-orange:hover {
        background-color: #fbb733;
      }

      .btn-default {
        color: #9A9998;
      }
    }

    .speaker-primary-action {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #605ca8;
      min-width: 180px;
      min-height: 233px;
      margin: 0 auto;
      cursor: pointer;
      color: white;
      padding: 10px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #555299;
      }
    }
  }
}