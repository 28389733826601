.navbar-search {
  position: relative;
  display: table;
  width: 100%;

  .Select {
    display: table-cell;
    width: 100%;
  }

  .Select-input > input, .Select-input > input:focus {
    background: transparent;
  }

  .btn-search {
    padding: 0px 10px;
    display: table-cell;
    line-height: 50px;
    font-Size: 18px;
    border: none;
    background: transparent;
    border-radius: 0px;
  }

  .Select-menu-outer {
    border: 1px solid #e6e6e6;
    width: inherit;
    text-align: left;
  }
}