.mp-footer {
  background-color: #222222;
  color: #FFFFFF;
  font-size: 14px;
  padding: 50px 0px;
  border-top: 1px solid #2e2e2e;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: relative;

  a, a:visited {
    color: #D8D8D8;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
  }

  a:hover {
    color: #FFFFFF;
    text-decoration: none;
  }

  .fa-inverse {
    color: #222222;
  }
}