.search-page {
  background-color: #f8f8f8;
  min-height: 90vh; }
  @media all and (max-width: 991px) {
    .search-page {
      font-size: 13px; } }
  .search-page h1 {
    color: #646464;
    font-size: 3em;
    line-height: 1em;
    text-transform: none; }
    @media all and (max-width: 991px) {
      .search-page h1 {
        font-size: 1.5em; } }
  .search-page .search-form {
    position: relative; }
    .search-page .search-form .busypane {
      position: absolute;
      top: 50%;
      width: 100%;
      height: 100%;
      z-index: 100; }
      .search-page .search-form .busypane .mp-secondary {
        border: #FAB033 thin solid;
        border-radius: 0.5em;
        padding: 0.1em 0.5em;
        background-color: #FAB033;
        color: white !important;
        font-size: 2em; }
      .search-page .search-form .busypane .blink_me {
        animation: blinker 1s linear infinite; }

@keyframes blinker {
  50% {
    opacity: 0; } }
    .search-page .search-form .virtual-popover {
      color: darkgoldenrod;
      background-color: cornsilk; }
      .search-page .search-form .virtual-popover.bottom > .arrow:after {
        border-bottom-color: cornsilk; }
    .search-page .search-form .search-form-submit {
      padding: 0px;
      margin-top: 25px; }
      @media all and (max-width: 991px) {
        .search-page .search-form .search-form-submit .btn {
          font-size: 15px;
          width: 100%; } }
    .search-page .search-form .num-results-text {
      font-weight: bold;
      color: #646464;
      font-size: 17px;
      line-height: 23px; }
      @media all and (max-width: 991px) {
        .search-page .search-form .num-results-text {
          font-size: 14px; } }
      .search-page .search-form .num-results-text .mp-secondary {
        border: #FAB033 thin solid;
        border-radius: 0.5em;
        padding: 0.1em 0.5em;
        background-color: #FAB033;
        color: white !important; }
      .search-page .search-form .num-results-text .mp-secondary.pulse {
        animation: num-results-pulse 0.5s ease-in-out;
        animation-iteration-count: 1;
        animation-fill-mode: both; }

@keyframes num-results-pulse {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(4, 4);
    filter: blur(4px); }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px); } }
    .search-page .search-form .searching-text {
      font-weight: normal;
      color: #C8C8C8;
      font-size: 2em;
      line-height: 23px;
      transition: all 1s ease; }
      @media all and (max-width: 991px) {
        .search-page .search-form .searching-text {
          font-size: 1.5em; } }
    .search-page .search-form .searching-text.visible {
      opacity: 1; }
    .search-page .search-form .searching-text.hidden {
      opacity: 0; }
    .search-page .search-form .sort-filter {
      font-size: 16px;
      line-height: 22px;
      color: #646464; }
      @media all and (max-width: 991px) {
        .search-page .search-form .sort-filter {
          font-size: 14px; } }
    .search-page .search-form .distance-filter {
      display: inline; }
    .search-page .search-form .search-form-top {
      margin: 0px auto;
      padding: 0 1em 2em 1em;
      border-radius: 3px;
      background-color: #FFFFFF;
      box-shadow: 0 1px 0 0 rgba(192, 192, 192, 0.5);
      position: relative; }
      @media all and (max-width: 991px) {
        .search-page .search-form .search-form-top {
          margin: 0px -15px;
          padding: 15px 30px; } }
      .search-page .search-form .search-form-top.busy {
        opacity: 0.25; }
    .search-page .search-form .search-form-dropdown {
      color: #646464;
      font-size: 16px;
      line-height: 22px;
      margin-Top: 30px; }
      @media all and (max-width: 991px) {
        .search-page .search-form .search-form-dropdown {
          font-size: 13px; } }
    .search-page .search-form .search-form-banner {
      margin: 2em 0 1em 0; }
      @media all and (max-width: 991px) {
        .search-page .search-form .search-form-banner {
          margin: 10px -15px; } }
    .search-page .search-form .search-form-bottom {
      margin: 2em 0 1em 0; }
      @media all and (max-width: 991px) {
        .search-page .search-form .search-form-bottom {
          margin: 10px -15px; } }
    .search-page .search-form .search-form-topic {
      padding: 0px;
      margin-top: 25px; }
      .search-page .search-form .search-form-topic label {
        font-size: 18px;
        font-weight: bold;
        line-height: 22px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-topic label {
            font-size: 15px; } }
      .search-page .search-form .search-form-topic .Select-menu-outer {
        z-index: 5; }
      .search-page .search-form .search-form-topic .Select-control {
        border-radius: 24px;
        border: 1px solid rgba(0, 0, 0, 0.54); }
    @media all and (min-width: 991px) {
      .search-page .search-form .search-form-tertiary {
        padding: 0px 100px; } }
    @media all and (min-width: 991px) {
      .search-page .search-form .search-form-secondary {
        margin: 25px auto;
        padding: 0px 100px; } }
    .search-page .search-form .search-form-type {
      padding: 0px;
      margin-top: 25px; }
      .search-page .search-form .search-form-type .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
        color: #555555 !important; }
      .search-page .search-form .search-form-type .Select-control {
        border-right: none;
        border-radius: 24px 0px 0px 24px;
        border: 1px solid rgba(0, 0, 0, 0.54);
        line-height: 20px;
        height: 46px;
        font-size: 15px;
        border-Right: none; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-type .Select-control {
            border-radius: 24px 24px;
            border: 1px solid rgba(0, 0, 0, 0.54); } }
        .search-page .search-form .search-form-type .Select-control .Select-value, .search-page .search-form .search-form-type .Select-control .Select-placeholder {
          padding: 12px 6px;
          line-height: 20px; }
        .search-page .search-form .search-form-type .Select-control .Select-placeholder {
          line-height: 20px; }
      .search-page .search-form .search-form-type label {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-type label {
            font-size: 13px; } }
    .search-page .search-form .search-form-available {
      padding: 0px;
      margin-top: 25px; }
      .search-page .search-form .search-form-available label {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-available label {
            font-size: 13px; } }
      .search-page .search-form .search-form-available .react-bootstrap-daterangepicker-container {
        width: 100%; }
      .search-page .search-form .search-form-available input, .search-page .search-form .search-form-available .btn {
        border: 1px solid rgba(0, 0, 0, 0.54);
        border-radius: 0px 0px 0px 0px;
        box-shadow: none;
        line-height: 20px;
        height: 46px;
        font-size: 15px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-available input, .search-page .search-form .search-form-available .btn {
            border-radius: 24px 24px;
            border: 1px solid rgba(0, 0, 0, 0.54); } }
    .search-page .search-form .search-form-topic-shared .Select-control {
      border-right: none !important;
      border-radius: 24px 0px 0px 24px !important; }
    .search-page .search-form .search-form-no-available input {
      border-left: 1px solid rgba(0, 0, 0, 0.54) !important;
      border-radius: 0px 0px 0px 0px !important; }
    .search-page .search-form .search-form-location {
      padding: 0px;
      margin-top: 25px; }
      .search-page .search-form .search-form-location label {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-location label {
            font-size: 13px; } }
      .search-page .search-form .search-form-location input {
        border-Top: 1px solid rgba(0, 0, 0, 0.54);
        border-Bottom: 1px solid rgba(0, 0, 0, 0.54);
        border-Right: none;
        border-Left: none;
        border-radius: 0px;
        box-shadow: none;
        line-height: 20px;
        height: 46px;
        font-size: 15px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-location input {
            border-radius: 24px 0px 0px 24px;
            border: 1px solid rgba(0, 0, 0, 0.54); } }
      .search-page .search-form .search-form-location .input-group-btn {
        box-shadow: none;
        line-height: 20px;
        height: 46px;
        font-size: 15px; }
        .search-page .search-form .search-form-location .input-group-btn .btn {
          z-index: 2;
          border: 1px solid rgba(0, 0, 0, 0.54);
          line-height: 20px;
          height: 46px;
          font-size: 15px; }
          @media all and (max-width: 991px) {
            .search-page .search-form .search-form-location .input-group-btn .btn {
              border-radius: 0px 24px 24px 0px; } }
    .search-page .search-form .search-form-no-location input, .search-page .search-form .search-form-no-location .btn {
      border-radius: 0px 24px 24px 0px !important; }
    .search-page .search-form .search-form-budget {
      padding: 0px;
      margin-top: 25px; }
      .search-page .search-form .search-form-budget label {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px; }
      .search-page .search-form .search-form-budget .Select-control {
        border-radius: 0px 24px 24px 0px;
        border: 1px solid rgba(0, 0, 0, 0.54);
        height: 46px; }
        @media all and (max-width: 991px) {
          .search-page .search-form .search-form-budget .Select-control {
            border-radius: 24px 24px; } }
        .search-page .search-form .search-form-budget .Select-control .Select-input, .search-page .search-form .search-form-budget .Select-control .Select-value-label, .search-page .search-form .search-form-budget .Select-control .Select-placeholder {
          line-height: 42px;
          font-size: 15px; }
    .search-page .search-form .hanging-indent-label label {
      line-height: 1.3rem;
      text-indent: -1.3rem;
      margin-left: 1.5rem; }
  .search-page .search-page-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .search-page .search-page-results .row:after, .search-page .search-page-results .row:before {
      display: none; }
  .search-page .search-more {
    border-radius: 24px;
    border: 1px solid rgba(192, 192, 192, 0.3);
    background-color: rgba(255, 255, 255, 0);
    background-image: none;
    color: #9A9998;
    padding: 10px 34px;
    transition: all 0.2s ease-in-out;
    margin: 40px auto;
    min-width: 170px; }
    .search-page .search-more:hover {
      background-image: none;
      background-color: white; }

.local-search-page {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px; }
  .local-search-page .search-form .num-results-text {
    color: white !important; }
  .local-search-page .search-form .num-results-text, .local-search-page .search-form .searching-text, .local-search-page .search-form .sort-filter {
    color: white !important; }

.hanging-indent-label label {
  line-height: 1.3rem;
  text-indent: -1.3rem;
  margin-left: 1.5rem; }
