/*

STOP!! This file is only for global css, not for component specific stuff

Webpack is setup to allow you to require css/less files directly in your JS.

So when you make a component that needs some css make a file next to the
component and name it the same. Then do this at the top fo your JS file

require("./MyComponent.less");

*/

$brand-success: #80BB3D;
$brand-info: #FAB033;
$btn-border-radius-base: 24px;
$btn-border-radius-large: 36px;
$btn-border-radius-small: 16px;
$icon-font-path: "../../fonts/bootstrap/" !default;

@import "../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "./less/font-awesome/scss/font-awesome.css";
@import "./less/zocial/zocial.css";
@import "./less/open-sans/open-sans.css";
@import "./less/react-toggle/react-toggle.css";
@import "./variables.scss";

html,
body {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

a {
  cursor: pointer;
}

.link {
  color: $link-color;
  cursor: pointer;
  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &.text-danger {
    color: $brand-danger;
  }
}

.layout-wrapper {
  min-height: 90vh;
  margin: 50px 0px 0px 0px;
}

form.mp-style-form {
  label.control-label {
    text-transform: uppercase;
    font-size: 14pt;
    font-weight: 200;
    letter-spacing: 2px;
  }
}

a.zocial:hover, a.zocial:focus {
  color: #fff;
  text-decoration: underline;
}

.help-block.instruction {
  border-left: solid thick cornflowerblue;
  padding: 0.5em 1em 0.5em 1em;
  color: #1e506d;
  margin-top: 1em;
  background-color: whitesmoke;

  .btnName {
    border-bottom: hsla(207, 39%, 70%, 1) medium solid;
  }

  &.text-warning {
    color: #eb9316;
    border-left: solid thick #eb9316;
    background-color: #fcf8e3;
  }

  &.right {
    border-left: none;
    border-right: solid thick cornflowerblue;
  }
}


@media all and (max-width: $screen-xs-max) {
  .btn.btn-block-xs {
    display: block;
    margin-Bottom: 5px;
  }
  button.btn.btn-block-xs {
    width: 100%
  }
  .modal-footer .btn.btn-block-xs + .btn.btn-block-xs {
    margin-Left: 0px;
  }
}

h4.help-block {
  line-height: 1.2em;
}

.bureaumode .hidden-bureaumode {
  display: none; //in bureau-mode, there's lots of promotional text we don't show
}

