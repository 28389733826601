.speaker-rating {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #605ca8;
  min-width: 160px;
  min-height: 160px;
  margin: 0 auto;
  color: #e2e1fd;
  padding: 10px;
  transition: all 0.2s ease-in-out;

  .clickable {
    color: white;
    text-decoration: underline;
    font-weight: bolder;
    text-underline-position: under;
  }

  &:hover {
    background-color: #555299;
  }

  .rating-number {
    font-size: 64px;
    //line-height: 64px;
    //background: url(https://s3.amazonaws.com/es.streamer/static/marketplace/star.svg);
    //background-size:cover;
    //background-repeat: no-repeat;
    text-align: center;
    width:1.5em;
    height:1.5em;
    background-color: #ffffff12;
    -webkit-mask-image: url(https://s3.amazonaws.com/es.streamer/static/marketplace/star.svg);
    mask-image: url(https://s3.amazonaws.com/es.streamer/static/marketplace/star.svg);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;


    .separator {
      opacity: 0.50;
    }

    .fa {
      font-Size: 24px;
    }

    .rating-number-label {
      font-Size: 10pt;
      opacity: 0.8;
      font-weight: bold;
    }

    .highlight {
      color: white;
      display: inline;
    }

    .lowlight {
      display: inline;
      opacity: 0.50;
      font-Size: 64px;
      line-height: 64px;
    }
  }

  .rating-review-box {
    font-size: 10px;
  }

  .rating-review-box-5 {
    color: #80BB3D;
  }
  .rating-review-box-4, .rating-review-box-3 {
    color: #f0ad4e;
  }
  .rating-review-box-2, .rating-review-box-1, .rating-review-box-0 {
    color: #d9534f;
  }
}