.paj-event-search-event-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .row:after, .row:before{
    display: none;
  }

  .paj-event-search-event {
    border: 1px solid transparent;
    border-Radius: 10px;

    &:hover {
      border: 1px solid #E8E8E8;
      border-Radius: 10px;
      box-shadow: 0 2px 24px 0 rgba(192, 192, 192, 1);
    }
  }
}