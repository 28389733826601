/*
    Document   : videoplayer.css
    Created on : Jun 7, 2011, 1:31:10 PM
    Author     : dreed
    Description:
        Styles for the eSpeakers Marketplace video player.
		(c) 2011 eSpeakers
*/

.MP-VideoPlayer {

  .VerticalScroll {
    min-height: 75px;
    max-height: 300px;
  }
  .HorizontalScroll {
    min-height: 75px;
    max-height: 75px;
    min-width: 100px;
    max-width: 100%;
  }

  .playlist-wrapper {
    height: 360px;

    /* PLAYLIST */
    .playlist-head .logo {
      /*width:105px;*/
      margin: 0 auto;
      padding: 10px;
      /*padding: 5px 25px 5px 25px;*/
      /*background-color: white;*/
      /*opacity:0.9;*/
      /*filter:alpha(opacity=90);*/
    }

    .item {
      cursor: pointer;
      clear: both;
    }

    .playlist-body .item p {
      cursor: pointer;
      line-height: 1em;
      font-size: 12px;
      padding: 11px 3px 0 0;
      margin-top: 0px;
      margin-left: 20px;
      white-space: normal;
      word-wrap: break-word;
    }
  }
}