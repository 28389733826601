.event-tabs {
  .wrap {
    text-align: center;
    margin: 20px;
    position: relative;
  }
  .btn-group {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    position: relative;
  }
  .wrap:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #ccc;
    background: black;
    width: 100%;
    transform: translateY(-50%);
  }
}