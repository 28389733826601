.mp-bg-header.navbar {
  margin-bottom: 0px;

  .navbar-nav > li.mp-nav-button > a {
    padding-Top: 10px;
    padding-Bottom: 10px;
    padding-Left: 11px;
    padding-Right: 11px;
  }

  .navbar-nav > li.mp-nav-button > a .btn {
    margin-Top: 0px;
    margin-Bottom: 0px;
  }

  .navbar-nav > li.mp-nav-button > a .btn:hover {
    box-shadow: 0 1px 0 0 rgba(192, 192, 192, 0.5);
  }
}

.mp-shortlist-topbar.pulse {
  animation: shortlist-pulse 0.5s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  //transform: all 0.25s ease-in-out;

  @keyframes shortlist-pulse {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: scale(2,2);
      filter: blur(2px);
    }

    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: scale(1,1);
      filter: blur(0px);
    }
  }
}