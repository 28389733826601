.MP-EventForm {
    .StepNumber {
        position: relative;
        margin-bottom: 15px;

        .StepNumber-n {
            float: left;
            background: #78A350;
            color: #fff;


            width: 39px;
            height: 39px;
            line-height: 39px;
            border-radius: 40px;

            font-size: 18px;
            text-align: center;
            font-weight: bold;
        }
        hr {
            border-top: none;
            border-bottom: 1px solid #d8d8d8;
            margin: 0;
            padding: 19px 0 0 0;
        }
    }
}

.react-datepicker {
    font-size: 1em;
}
.react-datepicker__header {
    padding-top: 0.8em;
}
.react-datepicker__month {
    margin: 0.4em 0.4em;
}
.react-datepicker__day-name, .react-datepicker__day {
    width: 1.5em;
    line-height: 1.5em;
    padding: 0px 3px;
}
.react-datepicker__current-month {
    font-size: 1em;
}
.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}
