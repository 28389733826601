.Location__suggestion-icon {
  margin-right: 8px;
}

.fa.fa-map-marker.header {
  color: #e53935;
}

.Location__search-input,
.Location__search-input:focus,
.Location__search-input:active {
  display: block;
  width: 100%;
}

.Location__autocomplete-container {
  position: absolute;
  top: 2px;
  z-Index: 2;
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.Location__suggestion-item {
  padding: 4px;
  text-align: left;
}

.Location__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Location__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Location__spinner {
  color: #18bc9c;
  font-size: 30px;
}

@media (max-width: 480px) {
  .autocomplete-container {
    text-align: left;
  }
}

@media (min-width: 768px) {
  //.alert {
  //  margin-left: auto;
  //  margin-right: auto;
  //  width: 50%;
  //  -webkit-animation: fadein .75s;
  //  -moz-animation: fadein .75s;
  //  -ms-animation: fadein .75s;
  //  -o-animation: fadein .75s;
  //  animation: fadein .75s;
  //}

  //.btn {
  //  margin-top: 1.5rem;
  //}

  //.form-group {
  //  display: inline-block;
  //  margin-left: auto;
  //  margin-right: auto;
  //  margin-top: 5rem;
  //  width: 50%;
  //}
}

.geocoding-results {
  text-align: left;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}