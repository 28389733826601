@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss";

.mp-message-scroll-list-item {

  cursor: pointer;
  position: relative;
  padding: 10px;
  margin-left: 15px;
  border-bottom: 1px solid #EFEFEF;

  &:hover {
    background-Color: #EFEFEF;
  }

  &.unread {
    font-Weight: bold;

    .message-content, .message-date {
      color: #000;
    }
  }

  &.selected {
    color: $component-active-color;
    background-Color: $component-active-bg;

    .message-content, .message-date, .message-sender, .message-event {
      color: $component-active-color;
    }
  }

  a {
    text-decoration: none;
    color: $text-color;
  }
}